import { Component, OnInit } from '@angular/core';
import { Router,NavigationExtras } from '@angular/router';

import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  name: any = '';
  mediaURL: any = '';

  options=['New Trip','All Trips','Truck Sent','Truck Loaded','Truck Reached','Truck Released',
  'Truck Unloaded','Assigned For Advance','Assigned For Final Payment','Lorry Challan Generated',
  'Deleted','Permanently Deleted'];
  optionsoperator=['All Trips','Truck Sent','Truck Loaded','Truck Reached','Truck Released',
  'Truck Unloaded','Assigned For Advance','Assigned For Final Payment','Lorry Challan Generated',
  'Deleted','Permanently Deleted']


  role:any='';
  image:any='';

  constructor(
    private router: Router,

    ) { 
      this.mediaURL = environment.mediaURL
      this.name = localStorage.getItem('name');
      this.role= localStorage.getItem('role');
      this.image=localStorage.getItem('cover')
    }

  ngOnInit() {
    
    this.mediaURL = environment.mediaURL;
    this.name = localStorage.getItem('name');
    this.role= localStorage.getItem('role');
  
  }

  selectitem(item:any)
  {
    
    if(item=='New Trip')
    {
      this.router.navigate(['home-page/directconsignment/'] );
    }
    else if(item=='All Trips')
    {
      this.router.navigate(['home-page/directconsignmentall/'] );
    }
    else if(item=='Assigned For Advance')
    {
      this.router.navigate(['home-page/assignedforadvance/'] );
    }
    else if(item=='Assigned For Final Payment')
    {
      this.router.navigate(['home-page/assignedforfinalpayment/'] );
    }
    else if(item=='Lorry Challan Generated')
    {
      this.router.navigate(['home-page/lrall/'] );
    }
    else 
    {  
      const navData: NavigationExtras = {
        queryParams: {
          stage: item
        }
      };
      this.router.navigate(['home-page/directconsignmentall/'], navData);

    }
    
  }


  openusers()
  {
    this.router.navigate(['consignmentmenu-page/userall/']);
  }

  opentrips()
  {
    this.router.navigate(['consignmentmenu-page/directconsignmentall/']);
  }

  ionViewDidEnter() {
    this.mediaURL = environment.mediaURL;
    this.name = localStorage.getItem('name');
    this.role= localStorage.getItem('role');
  }

 

  redirect(path) {
    this.router.navigate([path]);
  }
 


}
