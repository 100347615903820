import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  loading: HTMLIonLoadingElement;

  constructor(private loadingCtrl: LoadingController) { }
  async showLoading() {
    // const loading = await this.loadingCtrl.create({
    //   message: 'Digital Canvas is fetching data for you...',
    //   backdropDismiss: true
    // });

    const loading = await this.loadingCtrl.create({
      duration: 55000,
      message: '<div><img src="https://cdn.pixabay.com/animation/2022/10/11/03/16/03-16-39-160_512.gif" class="img-align"/></div>',
      spinner:null,
      translucent: false,
      // cssClass:'custom-loader-class',
      backdropDismiss: true,
      cssClass: 'custom-loading',
      mode: 'md',
    });

    await loading.present();
    return loading

  }
  async hideLoading() {
    await this.loadingCtrl.dismiss()
    this.loading.dismiss();
  }

  async dismissAllLoaders() {

    let topLoader = await this.loadingCtrl.getTop();
    console.log(topLoader)
    while (topLoader) {
      if (!(await topLoader.dismiss())) {
        await this.loadingCtrl.dismiss()
        this.loading?.dismiss();
        console.log("error")
        throw new Error('Could not dismiss the topmost loader. Aborting...');
      }
      topLoader = await this.loadingCtrl.getTop();
    }
  }
}
