

export const environment = {
  production: false,
  baseUrl: "https://apidigitalcanvas.indiantechco.com/index.php/",
  mediaURL: 'https://apidigitalcanvas.indiantechco.com/uploads/',
  authToken: 'digitalcanvas',
  youtubeapi:'AIzaSyAozDi-7UM69rKFELJUK9ClHai1fLw5iCY',
  version:'6',
  playstorelink:'https://play.google.com/store/apps/details?id=com.app.digitalcanvasssps',
  apiUrls: {
   
    apis: {
      Login:'users/Login',
      getParameterList: 'GlobalVariable/getAll',
      getType:'GlobalVariable/getByType',
      updateSettings:'GlobalVariable/editList',
      editByType:'GlobalVariable/editByType',


      getuserData: 'users/getuserData',
      saveuser: 'users/registerUser',
      getAlluser: 'users/getAll',
      getuserById: 'users/getById',
      deleteuser: 'users/deleteList',   
      updateuser: 'users/editList',
      accountapprovedmail: 'users/AccountApproved',
      accountblockedmail: 'users/AccountBlocked',

      getuserbyemail: 'users/getUserByEmail',
      getByEmail: 'users/getByEmail',
      sendOTP: 'users/sendOTP',
      resetpassword: 'users/resetpassword',
      verifyotp: 'users/verifyotp',
      getmemberData: 'member/getmemberData',
      updatemember: 'member/editList',
      savemember: 'member/registermember',
      getAllmember: 'member/getall',
      getmemberById: 'member/getById',
      deletemember: 'member/deleteList',   
      getexpenseData: 'expense/getexpenseData',
      updateexpense: 'expense/editList',
      saveexpense: 'expense/save',
      getAllexpense: 'expense/getall',
      getexpenseById: 'expense/getById',
      deleteexpense: 'expense/deleteList',  
      expensemail: 'expense/expensemail',  

      getpage1Data: 'page1/getpage1Data',
      updatepage1: 'page1/editList',
      savepage1: 'page1/save',
      getAllpage1: 'page1/getall',
      getpage1ById: 'page1/getById',
      deletepage1: 'page1/deleteList',  
      page1mail: 'page1/page1mail',


      
      getpage2Data: 'page2/getpage2Data',
      updatepage2: 'page2/editList',
      savepage2: 'page2/save',
      getAllpage2: 'page2/getall',
      getpage2ById: 'page2/getById',
      deletepage2: 'page2/deleteList',  
      page2mail: 'page2/page1mail',

     
      updatesponsors: 'sponsors/editList',
      savesponsors: 'sponsors/save',
      getAllsponsors: 'sponsors/getall',
      getsponsorsById: 'sponsors/getById',
      deletesponsors: 'sponsors/deleteList',  

      updatezone: 'zone/editList',
      savezone: 'zone/save',
      getAllzone: 'zone/getall',
      getzoneById: 'zone/getById',
      deletezone: 'zone/deleteList',  

      updatepuja: 'puja/editList',
      savepuja: 'puja/save',
      getAllpuja: 'puja/getall',
      getpujaById: 'puja/getById',
      deletepuja: 'puja/deleteList',  

      updatebooks: 'books/editList',
      savebooks: 'books/save',
      getAllbooks: 'books/getall',
      getbooksById: 'books/getById',
      deletebooks: 'books/deleteList',  
 
      updatehelpline: 'helpline/editList',
      savehelpline: 'helpline/save',
      getAllhelpline: 'helpline/getall',
      gethelplineById: 'helpline/getById',
      deletehelpline: 'helpline/deleteList', 
      getBookImages: 'books/getBookImages',
      getnirghantas: 'nirghanta/getnirghantas',
      getarchivephotos: 'archivephotos/getarchivephotos',
      getphotos: 'photos/getPhotos',
      getartists: 'artists/getartists',
      getmembers: 'members/getmembers',
      getRandomSponsor: 'sponsors/getRandom',
      getLangauges: 'lang',
    }
  }
};

